
















































import Tooltip from '@/components/tooltip/Tooltip.vue';
import * as vr from '@/helpers/validation';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

import { ValidationRules } from '@/api/schema';

interface IListCampaignType {
  title: string;
  description: string;
  tooltip?: string;
  icon: any;
  actionIcon?: any;
  isDisabled?: boolean;
  key: string;
}

export default {
  name: 'PromoCampaignsDialogTypes',
  mixins: [PromoCampaignFormMixin],
  components: {
    Tooltip
  },

  props: {
    tabContent: {
      type: Object
    }
  },
  data(): any {
    return {
      currentTab: ''
    };
  },

  computed: {
    campaignsList(): IListCampaignType[] {
      return this.tabContent.formContent.campaignsDataList;
    },

    rules(): ValidationRules {
      return {
        campaignTypeRadioGroup: [vr.required]
      };
    }
  }
};
